import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import backgroundSvg from '../assets/background.svg';
import rectSvg from '../assets/rect.svg';
import ellipseSvg from '../assets/ellipse.svg';
import { Link } from 'react-router-dom';

// Update blogs array without images
const blogs = [
  {
    id: 'NavigatingGenerativeAI',
    title: 'Navigating Generative AI: Opportunities and Challenges for Universities',
    date: '2024-01-22',
    summary: 'Exploring the potential of generative AI in higher education and the challenges it presents.',
    internalPost: true,
  },
  {
    id: 'post2',
    title: 'Harnessing the Power of Large Language Models: A Guide to Responsible Deployment',
    date: '2024-02-24',
    summary: 'An in-depth guide on deploying Large Language Models responsibly.',
    mediumUrl: 'https://medium.com/@branden.mcintyre/building-ai-products-mitigating-the-resiliency-challenge-of-llms-6e420b172aa8',
  },
  {
    id: 'post3',
    title: 'Building AI Products: Mitigating the Resiliency Challenge of LLMs',
    date: '2024-01-22',
    summary: 'Strategies to mitigate resiliency challenges when building AI products.',
    mediumUrl: 'https://medium.com/@branden.mcintyre/building-ai-products-mitigating-the-resiliency-challenge-of-llms-6e420b172aa8', // Replace with your actual Medium URL
  },
  {
    id: 'post4',
    title: 'Chevy Chatbot Misfire: A Case Study in LLM Guardrails and Best Practices',
    date: '2023-12-22',
    summary: 'Examining the Chevy Chatbot incident to understand effective LLM guardrails.',
    mediumUrl: 'https://medium.com/@branden.mcintyre/chevy-chatbot-misfire-a-case-study-in-llm-guardrails-and-best-practices-7ae319088e94', // Replace with your actual Medium URL
  },
  {
    id: 'post5',
    title: 'Should You Build Your Own LLM Stack or Use an API?',
    date: '2023-12-13',
    summary: 'Evaluating the pros and cons of building your own LLM stack versus leveraging existing APIs.',
    mediumUrl: 'https://medium.com/@branden.mcintyre/should-you-build-your-own-llm-stack-or-use-an-api-f277a484d968', // Replace with your actual Medium URL
  },
  {
    id: 'post6',
    title: 'Do You Really Need to Use the Latest LLM Model?',
    date: '2023-12-08',
    summary: 'Assessing whether adopting the latest LLM models offers significant benefits for your projects.',
    mediumUrl: 'https://medium.com/@branden.mcintyre/do-you-really-need-to-use-the-latest-llm-model-0c76df62a0ee', // Replace with your actual Medium URL
  },
  {
    id: 'post7',
    title: 'How to Keep Your LLM from Going Crazy: A Guide to Preventing Hallucinations',
    date: '2023-09-27',
    summary: 'Techniques and strategies to prevent hallucinations and ensure reliability in your LLM applications.',
    mediumUrl: 'https://medium.com/@branden.mcintyre/how-to-keep-your-llm-from-going-crazy-a-guide-to-preventing-hallucinations-4ece404ebee6', // Replace with your actual Medium URL
  },
];

const Blog = () => {
  return (
    <div className="relative w-full min-h-screen bg-white overflow-hidden">
      {/* Fixed Background SVG */}
      <div className="fixed inset-0 z-0">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ duration: 1 }}
          src={backgroundSvg}
          alt=""
          className="w-full h-full object-cover"
          aria-hidden="true"
        />
      </div>

      {/* Left rect.svg */}
      <div className="fixed left-0 top-1/2 -translate-y-1/2 w-32 lg:w-48 h-auto z-10">
        <motion.img
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 0.3, x: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          src={rectSvg}
          alt=""
          className="w-full h-auto"
          aria-hidden="true"
        />
      </div>

      {/* Top right ellipse.svg */}
      <div className="fixed right-0 top-0 w-48 lg:w-72 h-auto z-10">
        <motion.img
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 0.3, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          src={ellipseSvg}
          alt=""
          className="w-full h-auto"
          aria-hidden="true"
        />
      </div>

      {/* Content Container - Updated max-width and padding */}
      <div className="relative z-20 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        {/* Section Header */}
        <motion.div
          className="mb-16 text-center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-black mb-4
                         bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                         animate-gradient tracking-tight 
                         drop-shadow-lg leading-tight 
                         pb-[0.1em]
                         bg-clip-text text-transparent
                         mx-auto">
            Featured Blogs
          </h1>
          <p className="text-sm md:text-base text-gray-600 max-w-2xl mx-auto text-center">
            Discover our curated selection of articles that delve deep into the intricacies of AI and technology.
          </p>
        </motion.div>

        {/* Animated Blog Grid - Updated grid settings */}
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-auto"
          initial="hidden"
          animate="visible"
          variants={{
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.2,
              },
            },
            hidden: { opacity: 0 },
          }}
        >
          {blogs.map((blog, index) => (
            <motion.div
              key={blog.id}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 50 },
              }}
              whileHover={{ scale: 1.02 }}
              className="relative group h-full" // Added h-full
            >
              <motion.div
                className="h-full flex flex-col bg-gradient-to-r from-blue-400/10 via-purple-400/10 to-transparent 
                           backdrop-blur-sm
                           p-6 rounded-xl border border-blue-500/20
                           transform transition-all duration-300
                           group-hover:shadow-lg group-hover:shadow-blue-500/20
                           group-hover:border-blue-500/30"
              >
                {/* Animated Background */}
                <motion.div
                  className="absolute inset-0 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-transparent rounded-xl"
                  animate={{
                    background: [
                      'linear-gradient(45deg, rgba(59,130,246,0.05) 0%, rgba(147,51,234,0.05) 50%, transparent 100%)',
                      'linear-gradient(45deg, rgba(147,51,234,0.05) 0%, rgba(59,130,246,0.05) 50%, transparent 100%)',
                    ],
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    repeatType: "reverse"
                  }}
                />

                {/* Blog Content */}
                <div className="relative z-10 flex flex-col h-full">
                  <div className="mb-4">
                    <h2 className="text-xl font-bold mb-2
                                  bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                                  bg-clip-text text-transparent 
                                  leading-tight tracking-tight">
                      {blog.title}
                    </h2>
                    <p className="text-sm text-gray-500">
                      {new Date(blog.date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </p>
                  </div>

                  <p className="text-sm font-medium text-gray-600 leading-relaxed mb-6 line-clamp-3 flex-grow">
                    {blog.summary}
                  </p>

                  {blog.internalPost ? (
                    <Link
                      to={`/blog/${blog.id}`}
                      className="inline-flex items-center text-blue-600 hover:text-blue-700 
                                font-medium transition-colors duration-300 text-sm group mt-auto"
                    >
                      Read More
                      <motion.span
                        className="ml-1"
                        animate={{ x: [0, 5, 0] }}
                        transition={{ duration: 1.5, repeat: Infinity }}
                      >
                        →
                      </motion.span>
                    </Link>
                  ) : (
                    <a
                      href={blog.mediumUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-blue-600 hover:text-blue-700 
                                font-medium transition-colors duration-300 text-sm group mt-auto"
                    >
                      Read More
                      <motion.span
                        className="ml-1"
                        animate={{ x: [0, 5, 0] }}
                        transition={{ duration: 1.5, repeat: Infinity }}
                      >
                        →
                      </motion.span>
                    </a>
                  )}
                </div>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Blog;
