// BlogPost.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import backgroundSvg from '../assets/background.svg';

const blogPosts = {
  NavigatingGenerativeAI: {
    title: 'Navigating Generative AI: Opportunities and Challenges for Universities',
    date: '2025-01-14',
    author: 'Ajay Dankar',
    content: [
      {
        type: 'paragraph',
        text: 'In the rapidly evolving landscape of higher education, artificial intelligence (AI) represents both a transformative opportunity and a complex challenge. As AI reshapes industries, universities face a crossroads where innovation meets tradition, and generative AI presents both opportunities and obstacles.'
      },
      {
        type: 'paragraph',
        text: 'From groundbreaking research to administrative workflows, AI is redefining the operations of higher education. However, universities must navigate unique challenges, including data security, budget constraints, ethical considerations, and scalability concerns, to fully realize AI\'s potential.'
      },
      {
        type: 'h2',
        text: 'The AI Dilemma in Higher Education'
      },
      {
        type: 'paragraph',
        text: 'Picture a university today: cutting-edge research thrives in one building, while outdated systems challenge administrative offices in another. Meanwhile, faculty explore innovative ways to engage students. The promise of AI is tantalizing—personalized learning experiences, streamlined operations, and groundbreaking research capabilities—yet the path to implementation is riddled with obstacles.'
      },
      {
        type: 'h2',
        text: 'The Multifaceted Challenges'
      },
      {
        type: 'h3',
        text: '1. Data Security and Privacy'
      },
      {
        type: 'paragraph',
        text: 'Universities manage highly sensitive data, including research findings, student records, and intellectual property. Mishandling this data can lead to severe consequences, such as compliance violations under regulations like FERPA or GDPR. For example, a teaching assistant using generative AI to assist with assignments must ensure students\' personal information remains protected.'
      },
      {
        type: 'paragraph',
        text: 'Data breaches are a growing concern. A 2023 EDUCAUSE survey reported that 72% of institutions experienced significant breaches in the past two years, often due to inadequate security measures. Additionally, a 2024 Gartner report revealed that 45% of universities using AI faced security incidents, with breaches costing an average of $4.35 million, according to the Ponemon Institute.'
      },
      {
        type: 'paragraph',
        text: 'To mitigate these risks, universities must adopt robust data protection strategies, including stringent access controls, regular security audits, and AI solutions with built-in security features.'
      },
      {
        type: 'h3',
        text: '2. Fragmented AI Ecosystem'
      },
      {
        type: 'paragraph',
        text: 'The rapid proliferation of AI tools has created a fragmented ecosystem in many universities. Teaching aids, research platforms, and administrative tools often operate in isolation, creating silos that hinder interoperability and scalability.'
      },
      {
        type: 'paragraph',
        text: 'A 2023 McKinsey report found that 67% of universities using AI struggle with system integration issues, leading to duplicated efforts and higher costs. These silos also increase security vulnerabilities, as noted in a 2024 Forrester study, which revealed that 54% of institutions faced breaches due to poorly integrated AI tools.'
      },
      {
        type: 'paragraph',
        text: 'Adopting a unified approach to AI integration can mitigate these risks, enhance efficiency, and unlock the full potential of AI in higher education.'
      },
      {
        type: 'h3',
        text: '3. Budget Constraints vs. Innovation Demands'
      },
      {
        type: 'paragraph',
        text: 'Universities operate under tight budgets, balancing innovation with limited resources. Public universities, for instance, faced an average 5% budget reduction in 2023, while technology costs rose by 12%, according to the National Center for Education Statistics.'
      },
      {
        type: 'paragraph',
        text: 'Advanced AI tools often come with significant costs for licensing, deployment, and maintenance, making them inaccessible to smaller institutions. The 2024 Times Higher Education Digital Transformation Index highlighted that top-tier private universities invest three times more in AI infrastructure than public counterparts, widening the innovation gap.'
      },
      {
        type: 'h3',
        text: '4. Ethical Considerations and Governance'
      },
      {
        type: 'paragraph',
        text: 'As AI becomes integral to decision-making, ethical challenges arise. Admissions algorithms risk perpetuating historical inequities if trained on biased data, while AI-driven grading systems may lack transparency. A 2023 report emphasized the need for clear policies to address these concerns.'
      },
      {
        type: 'paragraph',
        text: 'Despite these challenges, only 20% of institutions have developed standardized AI governance frameworks. Universities must prioritize governance structures that ensure fairness, transparency, and accountability, aligning AI use with institutional values.'
      },
      {
        type: 'h3',
        text: '5. Student Engagement and Learning Outcomes'
      },
      {
        type: 'paragraph',
        text: 'AI holds significant potential to personalize learning and boost engagement. However, many faculty members face barriers such as complex interfaces, steep learning curves, and limited support for integrating AI into teaching. Current tools often fail to address diverse student needs, limiting their effectiveness.'
      },
      {
        type: 'paragraph',
        text: 'By investing in robust infrastructure and user-friendly platforms, universities can better support educators and enhance learning outcomes for all students.'
      },
      {
        type: 'h2',
        text: 'Bridging the Gap: A Holistic Approach to AI Integration'
      },
      {
        type: 'paragraph',
        text: 'The solution to AI adoption in higher education isn\'t about replacing human expertise but enhancing and supporting academic missions through intelligent systems. Here are key strategies for successful AI adoption:'
      },
      {
        type: 'paragraph',
        text: 'Unified and Secure AI Framework: Centralize data protection, ensure platform interoperability, and comply with educational regulations to minimize risks and enhance operational efficiency.'
      },
      {
        type: 'paragraph',
        text: 'Cost-Effective Scalability: Develop scalable infrastructures with predictable pricing models and efficiency-driven returns on investment to align technology adoption with institutional goals.'
      },
      {
        type: 'paragraph',
        text: 'Empowering Faculty and Researchers: Simplify technical complexities and provide tools that enable personalized learning, support research, and reduce administrative burdens.'
      },
      {
        type: 'paragraph',
        text: 'Ethical AI Governance: Establish governance frameworks that promote fairness, transparency, and accountability while safeguarding against biases.'
      },
      {
        type: 'h2',
        text: 'The Path Forward'
      },
      {
        type: 'paragraph',
        text: 'Successful AI integration in universities is not about wholesale replacement but intelligent augmentation. It\'s about creating an ecosystem where technology amplifies human capabilities rather than diminishes them.'
      },
      {
        type: 'paragraph',
        text: 'Forward-thinking universities are transforming how they create, consume, and interact with generative AI by providing faculty and students with access to teaching materials, administrative tools, professional development resources, and campus services – all within a framework of responsible and ethical AI use. Institutions that strategically prioritize security, ethics, and practical utility will not only adapt but thrive in the coming decades. Those that embrace AI as a collaborative tool, rather than a threat, will lead in education, research, and innovation.'
      },
      {
        type: 'paragraph',
        text: 'The AI revolution in higher education is no longer a distant future – it\'s happening now. By tackling challenges with comprehensive, secure, and ethical AI solutions, universities can turn potential obstacles into unprecedented opportunities for growth and innovation.'
      },
      {
        type: 'paragraph',
        text: 'For academic leaders navigating this complex landscape, the message is clear: The right AI strategy isn\'t about keeping pace with technology but about shaping the future of education by embracing this technology.'
      }
    ]
  }
  // ... other blog posts
};

const BlogPost = () => {
  const { postId } = useParams();
  const post = blogPosts[postId];

  if (!post) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-gray-600">Blog post not found</p>
      </div>
    );
  }

  return (
    <motion.div 
      className="relative w-full min-h-screen bg-white overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Background elements */}
      <div className="fixed inset-0 z-0">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.7 }}
          transition={{ duration: 1 }}
          src={backgroundSvg}
          alt=""
          className="w-full h-full object-cover"
          aria-hidden="true"
        />
      </div>
      
      {/* Content Container with increased top padding for navbar */}
      <div className="relative z-20 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        <article className="prose lg:prose-xl mx-auto">
          {/* Title Section */}
          <motion.div
            className="text-center mb-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4
                          bg-gradient-to-r from-blue-800 via-indigo-800 to-purple-800 
                          bg-clip-text text-transparent">
              {post.title}
            </h1>
            <div className="text-gray-500 space-y-1">
              <p>By {post.author}</p>
              <p>{new Date(post.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}</p>
            </div>
          </motion.div>

          {/* Blog Content */}
          <motion.div
            className="space-y-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {post.content.map((section, index) => {
              switch (section.type) {
                case 'h2':
                  return (
                    <h2 key={index} className="text-2xl font-bold mt-8 mb-4
                                             bg-gradient-to-r from-blue-800 to-purple-800 
                                             bg-clip-text text-transparent">
                      {section.text}
                    </h2>
                  );
                case 'h3':
                  return (
                    <h3 key={index} className="text-xl font-semibold mt-6 mb-3
                                             bg-gradient-to-r from-blue-700 to-purple-700
                                             bg-clip-text text-transparent">
                      {section.text}
                    </h3>
                  );
                default:
                  return (
                    <p key={index} 
                       className="text-gray-600 leading-relaxed"
                       dangerouslySetInnerHTML={{ __html: section.text }}>
                    </p>
                  );
              }
            })}
          </motion.div>
        </article>
      </div>
    </motion.div>
  );
};

export default BlogPost;
